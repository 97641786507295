@import "../../App.scss";
@import "../../../node_modules/mapbox-gl/dist/mapbox-gl.css";

.specifications {
  background-color: $color-light-2;
  box-shadow: 0px 0px 8px 1px rgba($color-dark-2, 0.25);
  border-radius: 4px;
  width: 20%;

  &__wrapper {
    margin: auto;

    @media screen and (max-width: $screen-md) {
      p {
        margin: $spacing-4 0;
      }
    }
  }

  &__header {
    justify-content: space-between;
    display: flex;
    margin: auto;
    margin-bottom: $spacing-5;
  }

  &__desc {
    text-align: left;
    display: block;

    p {
      color: $color-light-3 !important;
      margin: 0;
    }
  }

  &__title {
    display: flex;

    img {
      margin-right: $spacing-4;
    }

    > * {
      margin: auto 0;
    }
  }

  &__table {
    table {
      margin: auto;

      th {
        box-shadow: 0px 0px 2px 1px rgba($color-dark-2, 0.25);
        background-color: $color-dark-1;
        color: $color-light-1 !important;

        &:first-child {
          border-top-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
        }

        padding: $spacing-2 $spacing-6;
        text-align: center;
      }

      td {
        padding: $spacing-4 $spacing-6;

        &:first-child {
          font-weight: bold;
          color: $color-dark-1;
        }

        @media screen and (max-width: $screen-sm) {
          font-size: $size-2;
        }
      }

      tr {
        box-shadow: 0px 2px 4px 1px rgba($color-dark-2, 0.2);

        &:nth-child(odd) {
          background-color: $color-light-1;
        }

        &:nth-child(even) {
          background-color: $color-light-2;
        }

        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 4px;
            }

            &:last-child {
              border-bottom-right-radius: 4px;
            }
          }
        }
      }
    }
  }

  &__types {
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-bottom: $spacing-5;

    @media screen and (max-width: $screen-md) {
      .specifications__type {
        padding: $spacing-2 $spacing-4 !important;
      }
    }

    @media screen and (max-width: $screen-sm) {
      width: 100%;
      margin: 0;
      display: block;

      .specifications__type {
        display: inline-flex !important;
        width: 50% !important;
        margin: 0 !important;

        .icon {
          margin: 0;
        }

        &:nth-child(odd) {
          // margin-right: 8px;
        }

        &:nth-child(even) {
          // margin-left: 8px;
        }
      }
    }

    .specifications__type {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 0 $spacing-4;
      margin-bottom: $spacing-8;
      background-color: $color-light-2;
      padding: $spacing-4 $spacing-6;
      box-shadow: 0px 0px 2px 0px rgba($color-dark-1, 0.5);
      border-radius: 4px;

      .header {
        color: $color-dark-1 !important;
        font-weight: bold !important;
      }

      .icon {
        margin-right: $spacing-4;

        img {
          width: 50px;
          height: 50px;
        }
      }

      @media screen and (max-width: $screen-md) {
        margin: 0 $spacing-2;

        .header,
        .desc {
          font-size: $size-2 !important;
        }

        .icon {
          margin-right: $spacing-2;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      @media screen and (max-width: $screen-xs) {
        justify-content: center;
        text-align: center;
        padding: $spacing-2 0 !important;

        .icon {
          justify-content: center;
          display: flex;
          width: 100%;
          margin: auto !important;
        }

        .header,
        .desc {
          font-size: 12px !important;
        }
      }

      > * {
        margin: auto 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.certifications {
  margin-bottom: $spacing-8;
  background-color: $color-light-2;
  box-shadow: 0px 0px 8px 1px rgba($color-dark-2, 0.25);
  border-radius: 4px;
  width: 20%;
  animation: 2s ease-in-out;

  &__header {
    justify-content: space-between;
    display: flex;
    margin: auto;
    margin-bottom: $spacing-8;
  }

  &__desc {
    text-align: left;
    display: block;
    width: 65%;

    p {
      color: $color-light-3 !important;
    }
  }

  &__title {
    display: flex;
    margin-bottom: $spacing-2;

    img {
      margin-right: $spacing-4;
    }

    > * {
      margin: auto 0;
    }
  }

  &__options {
    display: flex;
    text-align: right;
    justify-content: flex-end;
    width: 30%;
    margin: auto 0;
  }

  &__wrapper {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin: 0 auto;
    justify-content: center;
  }

  &__container {
    width: 22%;
    text-align: center;

    &:not(:nth-child(4)) {
      margin-right: 3%;
    }

    &:last-child {
      margin-right: 0%;
    }

    @media screen and (max-width: $screen-sm) {
      margin: 0 !important;
      width: 50%;
      padding: $spacing-2;
      font-size: $size-1 !important;

      &:not(:nth-child(4)) {
        margin-right: 0%;
      }
    }
  }

  &__cert {
    display: inline-block;
    max-width: 250px;
    margin-bottom: $spacing-8;
    box-shadow: 0px 5px 5px 0px $color-light-3;

    @media screen and (max-width: $screen-md) {
      margin-bottom: $spacing-6;
    }

    @media screen and (max-width: $screen-sm) {
      margin-bottom: $spacing-4;
    }
    img {
      width: 100%;
      height: 330px;

      @media screen and (max-width: $screen-md) {
        height: 100%;
      }
    }

    .title {
      padding: $spacing-3 $spacing-2;
      max-width: inherit;

      @media screen and (max-width: $screen-md) {
        .title-wrapper {
          padding: 0 $spacing-2;

          > * {
            font-size: $size-1 !important;
          }
        }
      }

      &-wrapper {
        display: grid;
        height: calc(24.8px * 2);

        > * {
          display: flex;
          justify-content: center;
          color: $color-light-1 !important;
          margin: auto 0;
        }
      }

      &--local {
        border-top: 4px solid rgb(201, 13, 69);
        background: rgb(79, 22, 41);
      }

      &--international {
        border-top: 4px solid $color-primary;
        background-color: $color-dark-1 !important;
      }
    }
  }
}

.home {
  padding-top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  height: 600px;
  display: flex;
  margin: auto 0;
  justify-content: center;

  @media only screen and (max-device-width: 1500px) {
    background-attachment: scroll;
  }

  &__header {
    display: flex;
    margin: auto 0;
    color: white;
    z-index: 100;
    width: 1200px;
  }
}
