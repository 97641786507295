@import "./styles/variables";
@import "antd/dist/antd.css";

body {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: $color-light-1;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-header;
    font-weight: 500 !important;
    text-transform: uppercase;
  }

  div,
  p,
  span,
  text,
  a {
    font-family: $font-text !important;
    font-size: $size-3;
  }

  #root {
    width: 100%;
  }

  .ant-notification {
    .ant-notification-notice {
      background-color: $color-light-2;
      border: 1px solid $color-primary;
    }

    .ant-notification-notice-message {
      color: $color-primary-dark;
      font-weight: bold;
    }

    .ant-notification-notice-description,
    .ant-notification-notice-close {
      color: $color-dark-2;
    }
  }
}
