@import "../../App.scss";

@mixin wrapperStyle($header-color, $text-color, $bg-color) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $header-color;
    font-family: $font-header;
    font-weight: 500;
  }

  p,
  span,
  text,
  div {
    color: $text-color;
    font-family: $font-text;
    font-weight: 500;
  }

  letter-spacing: 0.2px;
  background: $bg-color;
}

#wrapper {
  padding: 4rem;

  .limiter {
    max-width: $screen-md;
    margin: auto;
  }

  // BACKGROUND MODES //
  &.bg-0 {
    @include wrapperStyle($color-dark-2, $color-light-2, $color-light-1);
  }
  &.bg-1 {
    @include wrapperStyle($color-dark-1, $color-light-3, $color-light-1);
  }

  &.bg-2 {
    @include wrapperStyle($color-dark-1, $color-light-3, $color-light-2);
  }

  &.bg-3 {
    @include wrapperStyle($color-light-1, $color-light-1, $color-dark-1);
  }

  &.bg-4 {
    @include wrapperStyle(
      $color-light-1,
      $color-light-1,
      linear-gradient(90deg, #0e2068 0%, #3e75c7 100%)
    );
  }

  @media screen and (max-width: 1500px) {
    padding: 2rem 1rem;
  }
}
