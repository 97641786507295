.spinner-wrapper {
  display: flex;
  height: 100vh;
  width: 100%;

  img {
    display: flex;
    justify-content: center;
    margin: auto;
  }
}
