@import "../../App.scss";

@mixin timelineBlocks {
  .event__year {
    margin-top: -12px;
    background-color: $color-light-2;
  }

  &:nth-child(1) {
    .event__block {
      background: linear-gradient(to right, #2199ff, #1b63c4);
    }

    .event__icon {
    }
  }

  &:nth-child(2) {
    .event__block {
      background: linear-gradient(to right, #135ebc, #6f40ba);
      margin-left: 20%;

      @media screen and (max-width: $screen-sm) {
        margin-left: 0
      }
    }

    .event__icon {
      border: 1px solid #135ebc;
    }
  }

  &:nth-child(3) {
    .event__block {
      background: linear-gradient(to right, #7428d7, #933079);
      margin-right: 20%;
      margin-left: auto;
    }

    .event__icon {
      border: 1px solid #7428d7;
    }
  }

  &:nth-child(4) {
    .event__block {
      background: linear-gradient(to right, #c01a57, #a2224b);
      margin-right: 0%;
      margin-left: auto;
    }

    .event__icon {
      border: 1px solid #c01a57;
    }
  }
}

.about {
  margin: auto;
  display: flex;

  @media screen and (max-width: 1100px) {
    display: block;
  }
}

.info-wrapper {
  display: block;
  width: 45%;

  @media screen and (max-width: 1100px) {
    width: 100%;
    text-align: center;
  }

  .info {
    &:last-child {
      .info-row:last-child {
        margin-bottom: 0;
      }
    }

    &-header {
      font-family: $font-header !important;
      border-left: 3px solid $color-primary;
      border-right: 3px solid $color-primary;
      font-size: $size-3;
      font-weight: bold;
      padding: $spacing-2 $spacing-8;
      background: $color-dark-1;
      color: $color-light-1 !important;
      width: fit-content;
      border-radius: 6px;

      @media screen and (max-width: 1100px) {
        margin: auto;
      }
    }

    &-desc {
      margin-top: $spacing-2;
      color: $color-light-3 !important;
      padding-left: 3px + $spacing-2;
      font-family: $font-text;

      @media screen and (max-width: 1100px) {
        margin: auto;
      }

      p {
        margin-bottom: 0;
      }
    }

    &-row {
      display: flex;
      margin-bottom: $spacing-4;

      @media screen and (max-width: 1100px) {
        justify-content: center;
        margin: $spacing-4 0 $spacing-6 0;
      }

      p:nth-child(even) {
        margin-left: $spacing-6;
      }
    }
  }
}

.timeline {
  width: 55%;
  margin-right: 0;

  @media screen and (max-width: 1100px) {
    margin: auto 0;
    width: 100%;

    h3 {
      text-align: center;
      margin-top: $spacing-8*2;
    }
  }

  p {
    color: $color-light-3 !important;
    margin-bottom: $spacing-6;
    text-align: justify;
  }
}

.event {
  display: flex;
  width: 100%;
  position: relative;

  &:last-child {
    height: 0;
  }

  @include timelineBlocks;

  &__dot {
    display: flex;
    border: 1px solid $color-primary;
    background-color: $color-primary;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: auto $spacing-2 auto $spacing-4;
    z-index: 100;
  }

  &__dash {
    position: absolute;
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed $color-light-3;
  }

  &__block {
    display: flex;
    padding: $spacing-3 $spacing-4;
    width: fit-content;
    margin: $spacing-2;
    border-radius: 6px;

    > * {
      font-size: $size-2;
      color: $color-light-2 !important;
      margin: auto 0;
    }

    @media screen and (max-width: $screen-sm) {
      width: 100%;
      margin: $spacing-2 0;
    }
  }

  &__year {
    line-height: 2;
    display: flex;
    margin: auto 0;
    text-align: left;
    padding-left: 0;

    color: $color-dark-1 !important;
    font-size: $size-1;
    position: absolute;
    top: 0;
  }

  &__icon {
    text-align: center;
    margin-right: $spacing-2;
    border-radius: 50%;

    @media screen and (max-width: $screen-md) {
      margin-right: $spacing-4;
    }

    img {
      width: 35px;
      height: 35px;
    }
  }

  &__timeline {
    margin: auto;
    margin-right: 0;
    width: calc(100% - 60px);
  }
}
