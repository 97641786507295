@import "../../App.scss";

.contact {
  display: flex;
  justify-content: space-between;

  .copy {
    cursor: pointer;
  }

  @media screen and (max-width: $screen-sm) {
    display: block;
  }

  &__section-2 {
    &:not(:first-child) {
      margin-top: $spacing-6;
    }

    .address-icons {
      margin-top: $spacing-2;

      > * {
        display: inline-block;
        margin: auto 0;

        &:not(:last-child) {
          margin-right: $spacing-3;
        }
      }
    }
  }

  &__section {
    width: 50%;

    &:not(:first-child) {
      @media screen and (max-width: $screen-sm) {
        margin-top: $spacing-4;
        padding-top: $spacing-4;
        border-top: 3px solid $color-primary-dark;
      }
    }

    @media screen and (max-width: $screen-sm) {
      width: 100%;
    }

    h3 {
      text-align: left;
      color: $color-primary !important;
    }
  }

  &__section-1 {
    display: block;
    justify-content: start;
    width: fit-content;

    @media screen and (max-width: $screen-sm) {
      display: block;
      justify-content: space-between;
      width: 100%;
    }

    .office,
    .director {
      width: fit-content;

      h4 {
        color: $color-primary !important;
      }

      div {
        display: flex;
        padding: $spacing-2 0;
      }

      p {
        margin: 0;
      }

      img {
        margin: auto 0;
        margin-left: $spacing-3;
      }

      span {
        font-family: $font-header !important;
        color: $color-primary !important;
      }
    }

    .director {
      @media screen and (max-width: $screen-md) {
        span {
          font-size: $size-1;
          color: $color-primary !important;
        }

        padding-right: $spacing-4;
      }
    }

    .office {
      margin-top: $spacing-4;
      padding-top: $spacing-5;
      border-top: 3px solid $color-primary-dark;

      @media screen and (max-width: $screen-md) {
        width: 100%;
        margin: 0;
        margin-top: $spacing-6;
        padding: 0;
        border-top: 0;
      }
    }
  }
}
