@import "../../App.scss";

.ant-tabs-nav {
  padding: 0 $spacing-4;
  margin: 0 !important;
}

.projects {
  $map-size: 500px;
  overflow: hidden;
  box-shadow: 0px 0px 3px 1px $color-light-3;
  border-radius: 5px;
  display: flex;
  height: $map-size;
  width: 100%;

  &__map {
    height: $map-size;
    width: 40%;

    @media screen and (max-width: $screen-sm) {
      display: none;
    }

    .dot {
      display: flex;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      margin: auto 0;
    }

    .block {
      z-index: 1000;
      padding: 1px;
      justify-content: center;
      display: flex;

      @mixin dot($bg, $border) {
        .dot {
          background-color: $bg;
          border: 1px solid $border;
        }
      }

      &--0 {
        @include dot($color-primary, $color-dark-2);
      }

      &--1 {
        @include dot(rgb(255, 57, 90), rgb(119, 0, 0));
      }

      &--2 {
        @include dot(rgb(46, 202, 59), rgb(0, 104, 9));
      }

      &--3 {
        @include dot(rgb(255, 225, 57), rgb(207, 161, 11));
      }

      &--4 {
        @include dot(rgb(154, 81, 250), rgb(94, 17, 194));
      }
    }

    .mapboxgl-control-container {
      display: none;
    }

    .mapboxgl-map {
      height: $map-size;
      position: relative;
    }

    .mapboxgl-marker {
      top: 0;
      overflow: hidden;
      position: absolute;
    }
  }

  &__accordion {
    // max-height: 320px;
    overflow: auto;

    .flex {
      display: flex;

      &:not(:last-child) {
        margin-bottom: $spacing-4;
      }

      > div {
        width: 50%;
      }
    }

    .label {
      font-weight: bold !important;
      color: $color-primary-dark !important;
      font-size: 10px;
      text-transform: uppercase;
    }
  }

  &__list {
    background-color: $color-light-2;
    width: 60%;
    // REMOVE AFTER FILTERS ARE IMPLEMENTED
    position: relative;
    overflow: auto;

    @media screen and (max-width: $screen-sm) {
      width: 100%;
    }

    h3 {
      padding: $spacing-4;
      z-index: 100;
      background-color: $color-dark-1;
      color: white !important;
      margin: auto 0;
      // REMOVE AFTER FILTERS ARE IMPLEMENTED
      position: sticky;
      top: 0;
      border-bottom: 1px solid lighten($color-light-3, 25%);
      margin-bottom: 0;

      img {
        margin-right: $spacing-2;
      }
    }
  }

  &__filter {
    padding: $spacing-4;
    padding-bottom: $spacing-2;
    background-color: darken($color-light-2, 5%);

    button {
      margin-bottom: $spacing-2;

      &:not(:last-child) {
        margin-right: $spacing-2;
      }
    }
  }
}

.ant-collapse {
  border: none;
}

.ant-collapse-header {
  color: $color-dark-1 !important;
}

.ant-collapse-item {
  background-color: $color-light-2;
  border: none;

  &:hover {
    background-color: darken($color-light-2, 2.5%);
  }
}

.ant-collapse-content-box {
  background-color: $color-light-1;
}

.ant-tabs-nav-wrap {
}
