@import "../../App.scss";

.nav {
  position: sticky;
  top: 0;
  z-index: 1000 !important;
  box-shadow: 0px 5px 15px -3px #111;
  transition: 0.5s ease-in-out;
  padding: 0 !important;

  &__stack {
    display: flex;
    padding: $spacing-3;
    justify-content: space-between;

    a {
      color: $color-light-1 !important;
    }

    @media screen and (max-width: $screen-sm) {
      height: 100%;
      display: block;
      font-size: 10px;
      padding-left: $spacing-2;
      padding-right: $spacing-2;

      .content {
        display: flex;
        justify-content: center;
      }

      img {
        width: 80px;
      }
    }

    @media screen and (max-width: $screen-md) {
      height: 100%;
      display: block;

      a {
        font-size: 10px;
      }
    }

    &--top {
      margin: auto 0 !important;
      justify-content: space-between;
    }

    &--inactive {
      display: none;
      background-color: $color-dark-2;
    }
  }

  &__progress-bar {
    height: 3px;
    width: 100%;
    display: flex;
    margin: auto 0;
    justify-content: center;
    background-color: $color-dark-1;

    .bar {
      width: 1200px;
    }

    .progress {
      background: #60abff;
      height: 2px;
      max-width: $screen-md;
    }
  }

  .contact {
    margin: auto 0;
    display: flex;

    @media screen and (max-width: $screen-md) {
      margin-top: $spacing-4;
    }

    @media screen and (max-width: $screen-sm) {
      display: none;
    }

    div {
      display: flex;
      font-size: $size-2;
      border-left: 3px solid $color-primary;
      padding-left: $spacing-3;
      margin-right: $spacing-6;
      font-family: $font-text;

      span {
        display: inline-block;
      }

      > * {
        margin: auto 0;
      }

      &:last-child {
        margin-right: 0 !important;
      }

      img:not(.copy) {
        width: 20px;
        height: 20px;
        margin: auto;
        margin-right: $spacing-2;
      }

      img.copy {
        margin-left: $spacing-2;
      }

      @media screen and (max-width: $screen-md) {
        padding: 0 $spacing-4;
        padding-right: 0;

        span {
          font-size: 12px;
        }
      }

      @media screen and (max-width: $screen-sm) {
        margin-top: $spacing-4;
        padding-left: $spacing-2;

        span {
          font-size: 12px;
        }
      }
    }
  }

  .links {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: auto 0;

    .content {
      margin: auto 0;
      text-transform: uppercase;
      text-align: center;
      font-size: $size-2;

      @media screen and (max-width: $screen-sm) {
        font-size: 10px;
        margin: auto 0;

        &:not(:last-child) {
          margin-right: $spacing-3 !important;
        }
      }

      &:not(:last-child) {
        margin-right: $spacing-8;
      }
    }

    @media screen and (max-width: $screen-md) {
      div,
      span {
        font-size: 12px;
      }
    }

    @media screen and (max-width: $screen-sm) {
      div,
      span {
        font-size: 10px;
      }
    }
  }

  .toggle {
    align-items: center;
    display: flex;

    > * {
      margin: auto 0;
    }
  }
}
