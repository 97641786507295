@import "../../App.scss";

.product-range {
  background-color: $color-light-2;

  .wrapper {
    &-top {
      margin-bottom: -1px;

      @media screen and (max-width: $screen-sm) {
        display: none;
      }
    }

    &-bottom {
      margin-top: -1px;

      @media screen and (max-width: $screen-sm) {
        display: none;
      }
    }
  }
}

.title {
  text-align: center;
}

.services-skewed {
  // transform: skewY(-4deg);
  margin: 64px 0px;
  padding: 64px 0px;
  margin-top: 0;

  > * {
    // transform: skewY(4deg);
  }

  @media screen and (max-width: $screen-md) {
    padding: $spacing-4 !important;
  }
}

.product-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 2px;
  background-color: #8cb0f9;

  .product {
    width: 25%;
    position: relative;
    padding: 2px;

    @media screen and (max-width: $screen-sm) {
      width: 50%;
    }

    .product-desc {
      height: 100%;
      width: 100%;
      z-index: 100;
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: auto 0;

      p {
        color: white !important;
        transition: 0.2s ease-in-out;
        text-align: center;
      }
    }

    &:hover {
      img {
        filter: none;
      }

      p {
        display: none;
      }
    }

    img {
      filter: brightness(0.4);
      transition: 0.2s ease-in-out;
      width: 100%;
      height: 100%;
    }
  }
}

.services {
  display: block;
  justify-content: space-between;
  padding: $spacing-8;

  @media screen and (max-width: $screen-md) {
    padding: $spacing-8 0;
  }

  @media screen and (max-width: $screen-sm) {
    .services__list .service {
      padding: 0 $spacing-2 !important;

      div {
        font-size: $size-1;
      }
    }
  }

  &__header {
    text-align: center;
    margin-bottom: $spacing-4;

    h3 {
      margin-top: $spacing-2;
      color: $color-primary !important;
      font-weight: 500 !important;
    }

    img {
      width: 50px;
      border: 2px solid $color-primary;
      border-radius: 50%;
    }
  }

  &__wrapper {
    text-align: center;
    margin: auto 0;

    div {
      color: $color-light-1 !important;
      font-weight: 500 !important;

      &:not(:last-child) {
        margin-bottom: $spacing-2;
      }
    }
  }

  &__list {
    display: block;
    justify-content: space-between;
    margin-top: $spacing-8;

    .service {
      margin-top: 0;
      margin-bottom: $spacing-6;
      display: inline-flex;
      width: 50%;

      div {
        margin: auto 0;
        color: $color-light-2 !important;
      }

      img {
        width: 50px;
        height: 50px;
        border: 2px solid $color-primary;
        border-radius: 50%;
      }

      &:nth-child(even) {
        text-align: right;
        justify-content: flex-start;
        flex-direction: row-reverse;
        padding-right: $spacing-4;

        img {
          margin-left: $spacing-4;
        }
      }

      &:nth-child(odd) {
        padding-left: $spacing-4;
        text-align: left;

        img {
          margin-right: $spacing-4;
        }
      }
    }
  }
}
