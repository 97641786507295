@import "../../App.scss";

.home {
  padding-top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  height: 600px;
  display: flex;
  margin: auto 0;
  justify-content: center;

  @media only screen and (max-device-width: 1500px) {
    background-attachment: scroll;
  }

  &__header {
    display: flex;
    margin: auto 0;
    color: white;
    z-index: 100;
    width: 1200px;
  }
}

$landingHeight: 50vh;

#particles {
  min-height: 500px;
  height: $landingHeight;
  width: 100%;
  background: linear-gradient(to left, #3f76c7, #0d2068) !important;
}

#landing {
  position: relative;
  min-height: 500px;
  height: $landingHeight;
  display: flex;
  justify-content: center;
  background: linear-gradient(to left, #3f76c7, #0d2068) !important;

  @media screen and (max-width: $screen-sm) {
    height: 100% !important;
  }
}

#text {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: $screen-md) {
    position: relative;
    display: block;
    background: linear-gradient(to left, #3f76c7, #0d2068) !important;
  }

  .wrapper {
    height: inherit;
    margin: auto 0;

    @media screen and (max-width: 1500px) {
      padding: 2rem 1rem !important;
    }
  }
}

.text-desc {
  text-align: justify;

  p {
    &:first-child {
      margin-top: 0;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 1200px;

  @media screen and (max-width: $screen-md) {
    width: 100%;
  }

  @media screen and (max-width: $screen-sm) {
    display: block;
  }

  .landing-text {
    color: $color-light-1 !important;
    margin: auto 0;
    width: calc(90% - 400px);

    @media screen and (min-width: 1200px) {
      width: 60%;
    }

    @media screen and (min-width: 800px) {
      width: 45%;
    }

    @media screen and (max-width: $screen-sm) {
      width: 100%;
    }

    .text-header {
      text-transform: uppercase;
      font-family: $font-header !important;
      color: $color-primary;
      display: inline-block;
      -webkit-background-clip: text;
      font-weight: 500;
      font-size: $size-4;
      margin-bottom: $size-4;
    }

    &-desc {
      font-family: $font-text;
      margin-top: $spacing-2;
      font-size: $size-3;
    }
  }

  .landing-img {
    display: inline-block;
    margin: auto;
    margin-right: $spacing-2;
    width: 400px;
    position: relative;
    height: 400px;

    @media screen and (max-width: $screen-md) {
      width: 45%;
    }

    @media screen and (min-width: 800px) {
      width: 50%;
    }

    @media screen and (max-width: $screen-sm) {
      width: 100%;
      margin-top: $spacing-6 * 2;
      padding: $spacing-2;
    }

    img {
      object-fit: cover;
    }

    .ant-carousel {
      outline: 2px solid $color-primary;
      outline-offset: 6px;
    }

    .slick-active {
      button {
        background-color: #7ebaff !important;
      }
    }

    img {
      width: 100%;
      text-align: center;
      height: 400px;
      display: flex;
    }
  }

  .contact {
    margin-top: $spacing-6;

    button {
      padding: $size-1 $size-2;
      color: $color-light-1;
      font-family: $font-header;
      background: rgba(0, 0, 0, 0.15) !important;
      border: 2px solid $color-primary;
    }
  }
}
