@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@400;500&display=swap");

$font-header: "Exo", sans-serif;
$font-text: "Quicksand", sans-serif;

$color-primary-dark: #0032cb;
$color-primary: #60abff;
$color-light-1: #eff2fc;
$color-light-2: #e0e5ee;
$color-light-3: #627497;
$color-dark-1: #001452;
$color-dark-2: #000030;

$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
$spacing-4: 16px;
$spacing-5: 20px;
$spacing-6: 24px;
$spacing-7: 30px;
$spacing-8: 36px;

$size-1: 12px;
$size-2: 14px;
$size-3: 16px;
$size-4: 20px;

$screen-xs: 500px;
$screen-sm: 800px;
$screen-md: 1200px;

@mixin shadows {
  box-shadow: 1px 1px 1px 1px rgba($color-dark-1, 0.5);
}
